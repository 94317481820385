import { createBrowserRouter } from "react-router-dom";
import LandingPageUdin from "../LandingPageUdin/Pages";
import NotFoundPage from "../LandingPageUdin/Components/notFound";
import DashbordUtama from "../paymentgateway/pages/landingpagepayment";
import Register from "../paymentgateway/pages/registrasi";
import Login from "../paymentgateway/pages/login";
import Beranda from "../paymentgateway/pages/beranda";
import Transaksi from "../paymentgateway/pages/transaksi";
import Saldo from "../paymentgateway/pages/saldo";
import AllData2 from "../paymentgateway/pages/alldata";
import Profile from "../paymentgateway/pages/profile";
import IpWhitlist from "../paymentgateway/pages/ipwhitlist";
import Pin from "../paymentgateway/pages/pin";
import AccsesKey from "../paymentgateway/pages/accses-key";
import Logout from "../paymentgateway/pages/logout";
import SummaryPerusahaan from "../paymentgateway/pages/alldata/dataperusahaan/summary";
import SummaryIndividu from "../paymentgateway/pages/alldata/dataindividu/summary";
import DashboardIndividu from "./DahsboardIndividu";
import ErrorPage from "../LandingPageUdin/Components/pageError";
import Proyek from "../paymentgateway/pages/proyek";
import AddProject from "../paymentgateway/pages/proyek/addProject";
import BankTransferOptions from "../paymentgateway/pages/proyek/pembayaran/bank";
import BankVerification from "../paymentgateway/pages/proyek/pembayaran/bca";
import EditProyek from "../paymentgateway/pages/proyek/editProject";

export const routes = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <DashbordUtama />,
  //   errorElement: <NotFoundPage />,
  // },

  // // Router Individu
  // {
  //   path: "me/",
  //   element: <DashboardIndividu />,
  //   errorElement: <ErrorPage />,
  //   children: [
  //     { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
  //     {
  //       path: "beranda",
  //       element: <Beranda />,
  //       errorElement: <NotFoundPage />,
  //     },
  //     {
  //       path: "transaksi",
  //       element: <Transaksi />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "saldo",
  //       element: <Saldo />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "proyek",
  //       element: <Proyek />,
  //       errorElement: <NotFoundPage />,
  //     },
  //     {
  //       path: "proyek/add",
  //       element: <AddProject />,
  //       errorElement: <NotFoundPage />,
  //     },
  //     {
  //       path: "proyek/edit",
  //       element: <EditProyek />,
  //       errorElement: <NotFoundPage />,
  //     },
  //     {
  //       path: "proyek/pembayaran",
  //       element: <BankTransferOptions />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "proyek/pembayaran/bankverivication",
  //       element: <BankVerification />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "data",
  //       element: <AllData2 />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "summary-perusahaan",
  //       element: <SummaryPerusahaan />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "summary-individu",
  //       element: <SummaryIndividu />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "profile",
  //       element: <Profile />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "ip-whitlist",
  //       element: <IpWhitlist />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "pin",
  //       element: <Pin />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "accses-key",
  //       element: <AccsesKey />,
  //       errorElement: <NotFoundPage />,
  //     },

  //     {
  //       path: "logout",
  //       element: <Logout />,
  //       errorElement: <NotFoundPage />,
  //     },
  //   ],
  // },

  // // {
  // //   path: "all-data",
  // //   element: <AllData />,
  // //   errorElement: <NotFoundPage />,
  // // },

  // // Route Regis & Login
  // {
  //   path: "register",
  //   element: <Register />,
  //   errorElement: <NotFoundPage />,
  //   // children: [
  //   //   {
  //   //     path: "/login",
  //   //     element: <Login />,
  //   //     errorElement: <NotFoundPage />,
  //   //   },
  //   // ],
  // },
  // {
  //   path: "login",
  //   element: <Login />,
  //   errorElement: <NotFoundPage />,
  // },

  // Route UdinPay

  {
    path: "/",
    element: <LandingPageUdin />,
    errorElement: <NotFoundPage />,
  },
]);
