import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ProjectData, ProjectResponse } from "../interface/proyek.interface";

// Define the initial state for the slice
interface ProyekState {
  projectData: ProjectData[] | null;
  selectedProjectId: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ProyekState = {
  projectData: null,
  selectedProjectId: null,
  status: "idle",
  error: null,
};

// Get data
export const fetchProjectData = createAsyncThunk<ProjectData[], string>(
  "project/fetchProjectData",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<ProjectResponse>(
        `${process.env.REACT_APP_API_URL}/v1.0/project-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch project data"
      );
    }
  }
);

// Delete data
export const deleteProjectData = createAsyncThunk<
  string,
  { id: string; token: string }
>("project/deleteProjectData", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/v1.0/delete-project/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return id;
    } else {
      throw new Error("Failed to delete project data");
    }
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete project data"
    );
  }
});

// Update data
export const updateProjectData = createAsyncThunk<
  ProjectData,
  { id: string; token: string; updatedData: Partial<ProjectData> }
>(
  "project/updateProjectData",
  async ({ id, token, updatedData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1.0/edit-project/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data; // Return the updated project data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update project data"
      );
    }
  }
);

// Create a new project
export const createProject = createAsyncThunk<
  ProjectData, // Return type should be ProjectData
  {
    projectName: string;
    callbackUrl: string;
    siteUrl: string;
    token: string;
  }
>(
  "project/createProject",
  async ({ projectName, callbackUrl, siteUrl, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0/create-project`,
        {
          nama_project: projectName,
          callback_url: callbackUrl,
          website_url: siteUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data; // Return the created project data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create project"
      );
    }
  }
);

const proyekSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setSelectedProjectId: (state, action: PayloadAction<string | null>) => {
      state.selectedProjectId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projectData = action.payload;
      })
      .addCase(fetchProjectData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch project data";
      })
      .addCase(deleteProjectData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProjectData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projectData =
          state.projectData?.filter(
            (project) => project._id !== action.payload
          ) || [];
      })
      .addCase(deleteProjectData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete project data";
      })
      .addCase(updateProjectData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProjectData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projectData =
          state.projectData?.map((project) =>
            project._id === action.payload._id ? action.payload : project
          ) || [];
      })
      .addCase(updateProjectData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to update project data";
      })
      .addCase(createProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projectData = state.projectData
          ? [...state.projectData, action.payload]
          : [action.payload];
      })
      .addCase(createProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to create project";
      });
  },
});

// Export the action to set the selected project ID
export const { setSelectedProjectId } = proyekSlice.actions;

export default proyekSlice.reducer;
