import React from "react";
import { BgheroUdin, ImagePhone } from "../../Assets/Images";

const dataValue = {
  id: 1,
  title:
    "Satu Aplikasi untuk Semua Transaksi, Prosesnya Cepat, Aman, dan Mudah",
};
const HeroUdin: React.FC = () => {
  const highlightText = (text: string) => {
    const keywords = ["Cepat", "Aman", "Mudah"];
    // Use regex to handle punctuation and extra spaces
    const regex = new RegExp(`(${keywords.join("|")})`, "gi");
    return text.split(regex).map((part, index) => {
      // Apply style to matching keywords
      if (keywords.includes(part.trim())) {
        return (
          <span key={index} className="text-white italic font-bold">
            {part}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };
  return (
    <section className="relative w-full bg-[#F7F7F7] h-[190px] md:h-[290px] lg:h-[530px] max-w-full overflow-hidden mt-[20px]">
      <div
        className="absolute inset-0 bg-cover bg-center rounded-3xl md:mx-[40px] mx-[20px]"
        style={{ backgroundImage: `url(${BgheroUdin})` }}
      >
        {/* Overlay to adjust content position and style */}
        <div className="flex flex-row justify-between items-center h-full px-4 lg:px-8 py-8">
          <div className="w-full lg:w-1/2 text-left">
            <div className="w-full md:mb-[40px] mb-[24px]">
              <p className="text-neutral-100 text-[16px] lg:text-[56px] md:text-[28px] font-normal font-Poppins">
                {highlightText(dataValue.title)}
              </p>
            </div>
            <div className="w-[117px] h-[27px] md:w-[173px] md:h-[46px] bg-black rounded-full ">
              <div className="w-[115px] h-[25px] md:w-[170px] md:h-[43px] md:text-[18px] text-[12px] px-[16px] py-[3px] md:px-[24px] md:py-[8px] bg-white text-red-600 rounded-full font-medium font-poppins">
                Download Now
              </div>
            </div>
          </div>
          <div className="w-full mt-[33px] md:mt-0 lg:w-[550px] md:w-[500px] flex justify-center bottom-0">
            <img
              src={ImagePhone}
              alt="phone"
              className="w-full h-auto object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroUdin;
