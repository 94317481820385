import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { FiltersTransaksiState } from "../reducers/filterTransaksi";
import axiosInstance from "../../../services/axiosInstance";

interface ExportDataState {
  loading: boolean;
  error: string | null;
  pdfUrl: string | null;
}

const initialState: ExportDataState = {
  loading: false,
  error: null,
  pdfUrl: null,
};

export const fetchExportData = createAsyncThunk<
  string,
  { merchantId: string; filters: FiltersTransaksiState } // Argument type
>(
  "export/fetchExportPdf",
  async ({ merchantId, filters }, { rejectWithValue }) => {
    try {
      const { startDate, endDate, selectedTipePembayaran, selectedStatus } =
        filters;
      const paymentMethod = selectedTipePembayaran.join(",");
      const status = selectedStatus.join(",");

      const response = await axiosInstance.get<{ pdfUrl: string }>(
        `/v1.0/export-pdf`,
        {
          params: {
            merchant_id: merchantId,
            start_date: startDate || "",
            end_date: endDate || "",
            payment_method: paymentMethod,
            status: status,
          },
        }
      );
      return response.data.pdfUrl;
    } catch (error) {
      return rejectWithValue("Failed to fetch PDF data");
    }
  }
);

// Create the slice
const ExportDataSlice = createSlice({
  name: "export",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.pdfUrl = null;
      })
      .addCase(
        fetchExportData.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.pdfUrl = action.payload;
        }
      )
      .addCase(fetchExportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default ExportDataSlice.reducer;
