import React, { useState, useRef } from "react";
import HeroUdin from "../Components/HeroUdin";
import Fitur from "../Components/Fitur";
import About from "../Components/About";
import Contact from "../Components/Contact";
import Help from "../Components/Help";
import Footer from "../Components/Footer";
import CamingSon from "./ComingSon";
import Navbar from "../Components/Navbar";

const HEADER_HEIGHT = 96; // Adjust as needed to fit your design

const LandingPageUdin: React.FC = () => {
  const [currentPage, setCurrentPage] = useState("Beranda");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showComingSon, setShowComingSon] = useState(false);

  const berandaRef = useRef<HTMLDivElement>(null);
  const fiturRef = useRef<HTMLDivElement>(null);
  const tentangKamiRef = useRef<HTMLDivElement>(null);
  const hubungiKamiRef = useRef<HTMLDivElement>(null);
  const bantuanRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = (page: string) => {
    setCurrentPage(page);
    setShowComingSon(page === "Download");

    const targetRef = {
      Beranda: berandaRef,
      Fitur: fiturRef,
      "Tentang Kami": tentangKamiRef,
      "Hubungi Kami": hubungiKamiRef,
      Bantuan: bantuanRef,
    }[page];

    if (targetRef?.current) {
      window.scrollTo({
        top: targetRef.current.offsetTop - HEADER_HEIGHT,
        behavior: "smooth",
      });
    }

    setIsMenuOpen(false);
  };

  return (
    <section className="max-w-[1440px] mx-auto ">
      <div className="fixed top-0 left-0 w-full z-40 bg-white">
        <Navbar
          currentPage={currentPage}
          isMenuOpen={isMenuOpen}
          handleMenuClick={handleMenuClick}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
      <section className="pt-[96px] grid grid-cols-1 ">
        {showComingSon ? (
          <CamingSon />
        ) : (
          <>
            <div ref={berandaRef}>
              <HeroUdin />
            </div>
            <div ref={fiturRef} className="">
              <Fitur />
            </div>
            <div ref={tentangKamiRef}>
              <About />
            </div>
            <div ref={hubungiKamiRef}>
              <Contact />
            </div>
            <div ref={bantuanRef}>
              <Help />
            </div>
            <Footer />
          </>
        )}
      </section>
    </section>
  );
};

export default LandingPageUdin;
