import React from "react";
import AboutWhy from "./AboutWhy";
import Promotion from "./Promotion";
import UdinPay from "./UdinPay";
import { Image3, ImagePhone2 } from "../../Assets/Images";

const About: React.FC = () => {
  return (
    <section className="max-w-[1440px] mx-auto  h-auto bg-[#F7F7F7] ">
      <div className="grid mb-[50px] rounded-[16px] bg-white lg:rounded-[32px] md:rounded-[10px]  md:h-[320px] lg:h-[550px] opacity-100 md:grid-cols-2 grid-cols-1 gap-10 items-center lg:mx-[56px] mx-[16px] md:mx-[32px]">
        <div className="font-poppins md:text-left pt-[20px] md:pt-0 ">
          <h1 className="lg:text-[40px] font-poppins font-semibold text-[18px] px-[24px]  ">
            Tentang Kami
          </h1>
          <p className="lg:text-[18px] lg:mt-[40px] mt-[16px] lg:pr-[60px] text-[14px] px-[16px] ">
            Selamat datang di Udin! Kami adalah layanan pembayaran digital yang
            berkomitmen untuk menyediakan solusi transaksi yang cepat, mudah,
            dan aman. E-wallet kami akan membantu memudahkan Anda dalam
            melakukan berbagai transaksi, mulai dari pembayaran tagihan hingga
            pembelian sehari-hari.
          </p>
          <div className="hidden md:block   lg:mt-[-43px] md:mt-[-41px]  absolute lg:h-[200px] lg:w-[200px] md:w-[-100px] md:h-[100px]">
            <img src={Image3} alt="" className="w-full h-full object-contain rounded-[32px]" />
          </div>
        </div>
        <div className="lg:w-[588px] md:mt-[33px] lg:h-[450px] md:w-[300px] md:h-[300px] lg:mt-[95px]  h-full relative">
          <img
            src={ImagePhone2}
            alt=""
            className="w-full h-full object-contain"
          />
        </div>
      </div>
      <AboutWhy />
      <Promotion />
      <UdinPay />
    </section>
  );
};

export default About;
