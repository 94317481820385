import React from "react";
import { FrameAbout, Icon3 } from "../../Assets/Images";

const Help: React.FC = () => {
  return (
    <section
      className="flex flex-col md:flex-row mb-[25px] lg:mb-[50px] p-6 md:p-10 lg:p-16 bg-no-repeat  bg-cover"
      style={{ backgroundImage: `url(${FrameAbout})` }}
    >
      <div className="text-white font-inter text-left flex-1 flex flex-col gap-4 ">
        <div className="bg-black w-20 pr-[5px] md:pb-[13px] pb-[8px] h-20 rounded-lg flex justify-center items-center">
          <div className="w-20  h-20 p-2 bg-gradient-to-b from-orange-500 to-red-600 rounded-lg shadow-lg flex justify-center items-center">
            <img
              src={Icon3}
              alt="Help Icon"
              className="w-10 h-10 object-contain"
            />
          </div>
        </div>
        <h1 className="text-2xl md:text-3xl lg:text-[40px] font-poppins text-[#F7F7F7] font-bold">
          Bantuan
        </h1>
        <p className=" text-[16px]  lg:text-[26px] text-[#F7F7F7] font-poppins leading-5 md:leading-[20px] lg:leading-[40px] md:w-[300px] lg:w-[400px] ">
          Halo, Kami di sini untuk membantu Anda. Hubungi kami dan tim kami akan
          segera merespons.
        </p>
      </div>
      <div className="mt-6 md:mt-0 md:ml-6 flex-1 lg:w-[800px] md:w-[1000px]">
        <form className="text-left font-poppins text-white">
          <label className="block text-sm font-medium mb-1">
            Email*
            <input
              type="email"
              placeholder="Email"
              className="w-full text-white p-2 border outline-none border-gray-300 rounded-md bg-transparent mt-1 placeholder-white"
              required
            />
          </label>
          <label className="block text-sm font-medium mt-4 mb-1">
            Subjek*
            <input
              type="text"
              placeholder="Judul Pertanyaan"
              className="w-full text-white p-2 border outline-none border-gray-300 rounded-md bg-transparent mt-1 placeholder-white"
              required
            />
          </label>
          <label className="block text-sm font-medium mt-4 mb-1">
            Deskripsi*
            <textarea
              placeholder="Isi Pertanyaan"
              className="w-full text-white p-2 h-[100px] lg:h-[200px] border outline-none border-gray-300 rounded-lg bg-transparent mt-1 placeholder-white"
              required
            />
          </label>
          <div className="flex justify-end mt-[16px]">
            <div className="bg-black rounded-full w-[200px] h-[40px] lg:w-[300px] lg:h-[50px] md:w-[150px] md:h-[40px]">
              <button
                type="submit"
                className="bg-white w-[200px] h-[40px] lg:w-[300px] md:w-[150px] md:h-[40px] lg:h-[50px] lg:mt-[-5px] ml-[-4px] mt-[-5px] lg:ml-[-5px] md:ml-[-3px] text-red-600 rounded-full py-2 px-6 font-poppins"
              >
                Kirim
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Help;
