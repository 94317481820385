import React from "react";
import { ImageGrop1, ImagePhone5, Pallet } from "../../Assets/Images";
import { IconBayar, IconDompet, IconPlan, IconPlus } from "../../Assets/Icons";

const fiturData = [
  {
    title: "Top up",
    description:
      "Top-up saldo jadi sangat mudah dan fleksibel. Anda bisa melakukannya kapan saja.",
    icon: IconPlus,
  },
  {
    title: "Transfer",
    description:
      "Kirim uang ke teman atau keluarga bisa dilakukan lewat aplikasi ini.",
    icon: IconPlan,
  },
  {
    title: "Tarik Tunai",
    description:
      "Tarik tunai lebih mudah dengan Udin, bisa dilakukan di ATM, Alfamart, dan Indomaret terdekat.",
    icon: IconDompet,
  },
  {
    title: "Bayar Tagihan",
    description: "Bisa untuk bayar tagihan belanja, listrik, dan lain-lain.",
    icon: IconBayar,
  },
];

const Fitur: React.FC = () => {
  return (
    <main className="bg-[#F7F7F7]">
      <section className="flex flex-col lg:py-[90px] py-[50px] md:py-[70px] md:flex-row text-left gap-6 lg:gap-[50px]">
        <div className="absolute h-[550px] hidden lg:block">
          <img
            src={ImageGrop1}
            alt="Background"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="absolute lg:hidden w-full md:w-[400px] md:h-[330px] lg:w-[800px] lg:h-[600px] left-1/2 md:left-20 transform -translate-x-1/2 mt-[25px] flex justify-center md:justify-start">
          <img
            src={Pallet}
            alt="Pallet"
            className="w-full h-full object-fill"
          />
        </div>
        <div className="relative w-[250px] pl-[18px] lg:pl-[45px] ml-[60px] md:ml-0 md:w-[390px] lg:w-[650px] mb-6 lg:mb-0">
          <img
            src={ImagePhone5}
            alt="Phone"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="px-[14px] md:px-[18px] lg:mt-[40px] md:mt-[14px] pr-4 lg:pr-[40px]">
          <div className="lg:ml-[-23px]">
            <h1 className="text-[22px] lg:text-[40px] font-poppins font-bold text-center md:text-left">
              Fitur
            </h1>
            <p className="text-[12px] lg:text-[18px] font-poppins text-center md:text-left">
              Jelajahi berbagai fitur menarik yang tersedia di dalam aplikasi
              kami. Dengan beragam opsi dan alat canggih, aplikasi ini dirancang
              untuk mempermudah dan memperkaya pengalaman Anda.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[40px] lg:gap-[30px] md:mt-[24px] mt-6 lg:mt-[40px]">
            {fiturData.map((fitur, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row items-center justify-center gap-2 lg:gap-[22px]"
              >
                <div className="bg-black rounded-xl lg:w-[103px] lg:h-[103px] w-[56px] h-[58px] flex md:pr-[6px] items-center md:items-start md:justify-start justify-center">
                  <div className="bg-[#F15A24] lg:w-[100px] lg:h-[100px] w-[56px] h-[56px] mt-[-5px] md:mt-[-3px] md:ml-[-1px] lg:mt-[-4px] lg:ml-[-5px] ml-[-5px] flex items-center justify-center rounded-xl">
                    <div className="lg:hidden lg:w-[56px] w-[30px]">
                      {fitur.icon("32", "32")}
                    </div>
                    <div className="hidden lg:block lg:w-[56px] w-[100px]">
                      {fitur.icon("56", "56")}
                    </div>
                  </div>
                </div>
                <div className="mt-0 lg:mt-[-12px] text-center md:text-left lg:w-[800px]">
                  <h1 className="font-poppins font-semibold text-[14px] lg:text-[24px]">
                    {fitur.title}
                  </h1>
                  <p className="text-[12px] lg:text-[16px] md:w-full lg:w-[350px] w-[250px] text-[#A3A3A3] font-poppins">
                    {fitur.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Fitur;
